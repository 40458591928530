import { Box, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import venueParking from './img/venueParking.png';
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) =>
    createStyles({
        container: {
            position: 'relative',
            marginTop: theme.spacing(2),
        },
        title: {
            textAlign: 'left',
            lineHeight: '2.5 !important',
            '@media (max-width: 600px)': {
                fontSize: '1.5rem !important',
            },
        },
        image: {
            width: '100%',
            height: 'auto',
            display: 'flex !important',
            alignContent: 'center !important',
            '@media (max-width: 600px)': {
                width: '100%',
                height: 'auto',
            }
        },
    })
);

const GoodToKnow = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    return (
        <Box className={classes.container}>
            <Typography variant="h1" gutterBottom>{t('gtk_title')}</Typography>
            <Typography variant="body1" gutterBottom>
                <strong>{t('gtk_car')}</strong>{t('gtk_car2')}
            </Typography>
                <img src={venueParking} alt="parking" className={classes.image}/>
            <Typography variant="body1" gutterBottom>
                <strong>{t('gtk_cameras')}</strong>{t('gtk_cameras2')}
            </Typography>
            <Typography variant="body1" gutterBottom>
                <strong>{t('gtk_shuttle')}</strong>{t('gtk_shuttle2')}
            </Typography>
            <Typography variant="body1">
                <strong>{t('gtk_chilly')}</strong>{t('gtk_chilly2')}
            </Typography>
        </Box>
    )
}

export default GoodToKnow;
