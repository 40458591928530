import React from 'react';
import { useTranslation } from 'react-i18next';
import {Box, Container, Typography} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import dresscode4K from "./img/dresscode4K.png";
import colors from "./img/colors.png";
import DressCodeGuidelines from "./DressCodeGuidelines";

// You can import dress code images or icons if needed

const useStyles = makeStyles((theme) =>
    createStyles({
        container: {
            position: 'relative',
        },
        title: {
            position: 'relative',
            zIndex: 10
        },
        subtitle: {
            position: 'relative',
            zIndex: 10
        },
        section: {
            fontSize: theme.text,
            marginTop: theme.spacing(2),
            zIndex: 10 ,
            position: 'relative',
            '@media (max-width: 600px)': {
                marginTop: theme.spacing(2),
                width: '100%',
                padding: 0,
            }
        },
        center: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            '@media (max-width: 600px)': {
                width: '100%',
                padding: 0,
            }
        },
        image: {
            width: '100%',
            height: 'auto',
            display: 'flex !important',
            alignContent: 'center !important',
            '@media (max-width: 600px)': {
                width: '100%',
                height: 'auto',
            }
        }
    })
);

const DressCode = () => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Box className={classes.container}>
            <Typography variant="h1" className={classes.title}>{t('dress_code_title')}</Typography>
            {/* Outfits Section */}
            <Box className={classes.section}>
                <Typography variant="h2" className={classes.subtitle}>{t('outfits')}</Typography>
                <Container className={classes.center}>
                    <img className={classes.image} src={dresscode4K} alt={"Outfits"}/>
                </Container>
            </Box>
            {/* Colors Section */}
            <Box className={classes.section}>
                <Typography variant="h2" className={classes.subtitle}>{t('colors')}</Typography>
                <Typography variant="h4">{t('colors_subtitle')}</Typography>
                {/* Add color samples or other content */}
                <Container className={classes.center}>
                    <img className={classes.image} src={colors} alt={"Colors"}/>
                </Container>
            </Box>
            <DressCodeGuidelines/>
        </Box>
    );
};

export default DressCode;
