import {createTheme} from "@mui/material";

const myTheme = createTheme({
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    backgroundColor: '#4a7c59', /* Matched to the green color of the title */
                    color: '#ffffff', /* White text */
                    border: 'none',
                    padding: '10px 20px',
                    borderRadius: '5px',
                    fontFamily: 'Citizen Decorative, sans-serif', /* Use the font family that matches your theme */
                    fontSize: '16px',
                    cursor: 'pointer',
                    transition: 'background-color 0.3s ease',
                    textAlign: 'center',
                    display: 'block',
                    margin: '0 auto', /* This will center the button */
                    '&:hover': {
                        backgroundColor: '#3a5c47', /* A darker shade for hover */
                    },
                    '@media (max-width:600px)': {
                        fontSize: '1.0rem !important',
                    },
                },
            },
        },
    },
    palette: {
        background: {
            normal: '#edf0e6', // Normal earthy color
        },
        title: {
            primary: '#444034', // Dark text color
        },
        text: {
            primary: '#444034', // Dark text color
        },
    },
    typography: {
        fontFamily: '"didotRegular", "Citizen Decorative", serif',
        h1: {
            textAlign: 'left',
            fontFamily: 'title, Citizen Decorative',
            fontSize: '3rem',
            fontWeight: 400,
            lineHeight: 1.2,
            letterSpacing: '0.01em',
            marginBottom: '1.5rem',
            '@media (max-width:600px)': {
                fontSize: '2.0rem',
                textAlign: 'center',
            },
        },
        h2: {
            fontFamily: 'subtitle, Citizen Decorative',
            fontSize: '2.0rem',
            fontWeight: 500,
            lineHeight: 1.2,
            '@media (max-width:600px)': {
                fontSize: '1.5rem',
            },
        },
        h4: {
            fontFamily: 'subtitle, Citizen Decorative',
            fontSize: '1.0rem',
            fontWeight: 200,
            lineHeight: 0.8,
            '@media (max-width:600px)': {
                fontSize: '1rem',
            },
        },
        body1: {
            fontFamily: 'didotRegular, Citizen Decorative',
            '@media (max-width:600px)': {
                fontSize: '1rem',
            },
        },
        body2: {
            fontFamily: 'didotItalic, Citizen Decorative', // Use the desired font
            fontStyle: 'italic',
        },
    },
});

export default myTheme;