import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SHA256 from 'crypto-js/sha256';


const ProtectedContent = ({ isAuthenticated, setAuthenticated, children }) => {
    const navigate = useNavigate();

    const [password, setPassword] = useState('');

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const checkPassword = async () => {
        try {
            const hashedPassword = SHA256(password).toString();
            const response = await fetch(`https://7hs6vn1n08.execute-api.eu-north-1.amazonaws.com/production
/api/check-password?password=${encodeURIComponent(hashedPassword)}`);
            const text = await response.text();
            if (text === "true") {
                setAuthenticated(true);
                localStorage.setItem('isLoggedIn', 'true');
                navigate('/');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    // At the start of your component or in an effect hook
    React.useEffect(() => {
        const isLoggedIn = localStorage.getItem('isLoggedIn');
        if (isLoggedIn === 'true') {
            setAuthenticated(true);
        }
    }, []);

    if (!isAuthenticated) {
        return (
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" style={{ height: '100vh' }}>
                <Typography variant="h5" style={{ marginBottom: '20px' }}>
                    Enter Password to Access
                </Typography>
                <TextField
                    type="password"
                    label="Password"
                    variant="outlined"
                    value={password}
                    onChange={handlePasswordChange}
                    style={{ marginBottom: '20px' }}
                />
                <Button variant="contained" color="primary" onClick={checkPassword} onTouchEnd={checkPassword}>
                    Enter
                </Button>
            </Box>
        );
    }

    return <>{children}</>;
};

export default ProtectedContent;
