import React, {useContext, useState} from 'react'
import {
    Button,
    Container,
    FormControl,
    FormControlLabel,
    FormLabel, Link,
    Radio,
    RadioGroup,
    TextField,
    Typography
} from "@mui/material"
import { makeStyles } from '@mui/styles';
import {useTranslation} from "react-i18next";
import {LanguageContext} from "../../App";
import {useNavigate} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    formContainer: {
        backgroundColor: theme.palette.background.normal,
        color: theme.palette.text.primary,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(4),
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[1],
    },
    formTitle: {
        color: '#4a4a4a',
        marginBottom: theme.spacing(2) + '!important',
    },
    textField: {
        '& .MuiInputBase-input': {
            color: '#4a4a4a', // Example text color
        },
        '& .MuiInputLabel-root': {
            color: '#4a4a4a', // Example label color
        },
        margin: theme.spacing(1),
    },
    submitButton: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
        backgroundColor: '#8a8a8a', // Example button color
        '&:hover': {
            backgroundColor: '#6a6a6a', // Example button hover color
        },
    },
}));
const RSVPForm = () => {
    let navigate = useNavigate();
    const { t } = useTranslation();
    const { selectedLanguage } = useContext(LanguageContext);
    const classes = useStyles();
    const [name, setName] = useState('')
    const [otherNames, setOtherNames] = useState('')
    const [email, setEmail] = useState('')
    const [adults, setAdults] = useState(0)
    const [children, setChildren] = useState(0)
    const [message, setMessage] = useState('')
    const [dietaryRestrictions, setDietaryRestrictions] = useState('')
    const [rsvp, setRSVP] = useState('')
    const [error, setError] = useState('')
    const [boatTrip, setBoatTrip] = useState('')

    const yesLabel = t('form.yes');
    const noLabel = t('form.no');
    const errorLabel = t('form.error');
    const attendingLabel = t('form.labels.attending');
    const nameLabel = t('form.labels.name');
    const otherNamesLabel = t('form.labels.otherNames');
    const numberAdultsLabel = t('form.labels.numberAdults');
    const numberChildrenLabel = t('form.labels.numberChildren');
    const emailLabel = t('form.labels.email');
    const messageLabel = t('form.labels.message');
    const dietaryRestrictionsLabel = t('form.labels.dietaryRestrictions');
    const boatTripLabel = t('form.labels.joinBoatTrip');
    const moreInfoLabel = t('form.labels.moreInfo');
    const submitButtonLabel = t('form.submitRSVP');

    const handleRSVPChange = (event) => {
        setRSVP(event.target.value);
    };

    const handleBoatTripChange = (event) => {
        setBoatTrip(event.target.value);
    };

    // Use constants above and set values from parameters
    const handleRSVP = (e) => {
        const formEle = document.querySelector('form')
        e.preventDefault()

        // Check if RSVP is selected
        if (rsvp !== 'yes' && rsvp !== 'no') {
            setError({errorLabel});
            return
        }
        setError('');

        const formData = new FormData(formEle)
        formData.append('CreatedAt', new Date().toLocaleString())
        formData.append('Language', selectedLanguage)
        // Perform RSVP submission logic here
        fetch("https://script.google.com/macros/s/AKfycbysafgKG4joBwWBOm7M8H6sYn3oifGuFUHCWua3qrYgyuwnIHSzaH1J3qXOlL6iqmusTg/exec",
            {
                    method: "POST",
                    body: formData
            })
            .then(res => res.text())
            .then(res => {
                console.log(res)
            })
            .catch(err => {
                console.log(err)
            }, [])

        // Reset the form after submission
        setName('');
        setOtherNames('');
        setEmail('');
        setAdults(0);
        setChildren(0);
        setMessage('');
        setDietaryRestrictions('');
        setRSVP(false);
        setBoatTrip(false);

        navigate('/');
    };

    return (
        <Container className={classes.formContainer}>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <Typography variant="h1" className={classes.formTitle}>RSVP</Typography>
            <form className="form" onSubmit={handleRSVP}>
                <FormControl component="fieldset" margin="normal">
                    <FormLabel component="legend">{attendingLabel}</FormLabel>
                    <RadioGroup
                        aria-label="rsvp"
                        name="RSVP"
                        value={rsvp}
                        onChange={handleRSVPChange}
                        row
                    >
                        <FormControlLabel
                            value="yes"
                            control={<Radio color="primary" />}
                            label={yesLabel}
                        />
                        <FormControlLabel
                            value="no"
                            control={<Radio color="primary" />}
                            label={noLabel}
                        />
                    </RadioGroup>
                </FormControl>
                <TextField
                    label={nameLabel}
                    name="Name"
                    fullWidth={true}
                    margin="normal"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <TextField
                    className={classes.textField}
                    label={otherNamesLabel}
                    name="OtherNames"
                    fullWidth={true}
                    margin="normal"
                    value={otherNames}
                    onChange={(e) => setOtherNames(e.target.value)}
                />
                <TextField
                    className={classes.textField}
                    label={numberAdultsLabel}
                    name="NumberAdults"
                    fullWidth={true}
                    margin="normal"
                    type="number"
                    value={adults}
                    onChange={(e) => setAdults(e.target.value)}
                />
                <TextField
                    className={classes.textField}
                    label={numberChildrenLabel}
                    name="NumberChildren"
                    fullWidth={true}
                    margin="normal"
                    type="number"
                    value={children}
                    onChange={(e) => setChildren(e.target.value)}
                />
                <TextField
                    className={classes.textField}
                    label={emailLabel}
                    name="Email"
                    fullWidth
                    margin="normal"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                    className={classes.textField}
                    label={messageLabel}
                    name="Message"
                    fullWidth
                    margin="normal"
                    multiline
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                />
                <TextField
                    className={classes.textField}
                    label={dietaryRestrictionsLabel}
                    name="Allergies"
                    fullWidth
                    margin="normal"
                    multiline
                    value={dietaryRestrictions}
                    onChange={(e) => setDietaryRestrictions(e.target.value)}
                />
                <FormControl component="fieldset" margin="normal">
                    <FormLabel component="legend">{boatTripLabel}</FormLabel>
                    <Typography variant="body2" className={classes.infoLink}>
                        <Link href="/#boat-trip-info" underline="hover" target="_blank" rel="noopener noreferrer">
                            {moreInfoLabel}
                        </Link>
                    </Typography>
                    <RadioGroup
                        aria-label="boat-trip"
                        name="BoatTrip"
                        value={boatTrip}
                        onChange={handleBoatTripChange}
                        row
                    >
                        <FormControlLabel
                            value="yes"
                            control={<Radio color="primary" />}
                            label={yesLabel}
                        />
                        <FormControlLabel
                            value="no"
                            control={<Radio color="primary" />}
                            label={noLabel}
                        />
                    </RadioGroup>
                </FormControl>
                <Button type="submit" variant="contained" color="primary" className={classes.submitButton}>
                    {submitButtonLabel}
                </Button>
            </form>
        </Container>
    );
};

export default RSVPForm;
