import React from 'react'
import { useTranslation } from 'react-i18next'
import {Box, Typography} from "@mui/material"
import {createStyles,makeStyles} from "@mui/styles"
import projekt2 from './img/location.png'

const useStyles = makeStyles((theme) =>
    createStyles({
        title: {
            textAlign: 'left',
            marginBottom: theme.spacing(2),
        },
        info: {
            textAlign: 'center',
        },
        container: {
            position: "relative",
        },
        image: {
            width: '100%',
            height: 'auto',
            display: 'flex',
            alignContent: 'center',
            '@media (max-width: 600px)': {
                width: '100%',
                height: 'auto',
            }
        },
        desktopImageStyle: {
            display: 'block', // shown by default
            width: '100%',
            height: 'auto',
            '@media (max-width: 600px)': {
                display: 'none', // hidden on mobile
            },
        },
        mobileImageStyle: {
            display: 'none', // hidden by default
            width: '100%',
            height: 'auto',
            '@media (max-width: 600px)': {
                display: 'block', // shown on mobile
            },
        },
     })
)

const Location = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    return (
        <Box maxWidth="md" className={classes.container}>
            <Box className={classes.info}>
                <Typography variant="h1" className={classes.title}>{t('location_title')}</Typography>
            </Box>
            <a href="https://maps.app.goo.gl/nASq9Dcb9GBwzcZw8" target="_blank" rel="noopener noreferrer">
                <img className={classes.image} src={projekt2} alt="location"/>
            </a>
            <Typography variant="body1"  className={classes.info}>{t('location')}</Typography>
            <Typography variant="body1" className={classes.info}>{t('location_desc')}</Typography>
        </Box>
    );
};

export default Location;
