import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom';
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import {I18nextProvider} from 'react-i18next'
import i18n from 'i18next'
import myTheme from "./theme"
import {ThemeProvider} from "@mui/material"
import './styles/fonts.css'



i18n.init({
    resources: {
        en: {
            translation: require('./locales/en.json')
        },
        es: {
            translation: require('./locales/es.json')
        },
        de: {
            translation: require('./locales/de.json')
        }
    },
    lng: 'en', // default language
    interpolation: {
        escapeValue: false
    }
});

const rootElement = document.getElementById('root');

ReactDOM.render(
    <I18nextProvider i18n={i18n}>
        <link rel="stylesheet"
              href="https://fonts.googleapis.com/css2?family=Cinzel+Decorative:wght@400;700&display=swap"/>
        <ThemeProvider theme={myTheme}>
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </ThemeProvider>
    </I18nextProvider>,
    rootElement
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
