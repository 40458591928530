import React from 'react';
import { Box, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) =>
    createStyles({
        dressCodeNotice: {
            position: 'relative',
            marginTop: theme.spacing(2),
        },
        title: {
            textAlign: 'left',
            lineHeight: '2.5 !important',
            '@media (max-width: 600px)': {
                fontSize: '1.5rem !important',
            },
        },
        center: {
            textAlign: 'center',
            marginTop: theme.spacing(2) + ' !important'
        },
        leftIntro: {
            textAlign: 'left',
            marginBottom: theme.spacing(2) + ' !important',
            marginTop: theme.spacing(2) + ' !important'
        },
        left: {
            textAlign: 'left',
        },
    })
);

const DressCodeGuidelines = () => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Box className={classes.dressCodeNotice}>
            <Typography variant="h2" className={classes.title}>{t('dcg_title')}</Typography>
            <Typography variant="body1" className={classes.leftIntro} gutterBottom>{t('dcg_suggestions')}</Typography>
            <Typography variant="body1"><strong>{t('dcg_men1')}</strong>{t('dcg_men2')}</Typography>
            <Typography variant="body1"><strong>{t('dcg_women1')}</strong>{t('dcg_women2')}</Typography>
            <Typography variant="body1" className={classes.center}>{t('dcg_bye')} 💃🕺</Typography>
        </Box>
    );
};

export default DressCodeGuidelines;
