import {Box, Typography} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) =>
    createStyles({
        container: {
            position: 'relative',
            marginTop: theme.spacing(2),
        },
        title: {
            textAlign: 'left',
            lineHeight: '2.5 !important',
            '@media (max-width: 600px)': {
                fontSize: '1.5rem !important',
            },
        },
    })
);

const BoatTrip = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    return (
        <Box className={classes.container}>
            <Typography variant="h1">{t('sunday')}</Typography>
            <Typography variant="h2" gutterBottom>{t('ahoy')}</Typography>
            <Typography variant="body1" gutterBottom>{t('our_plan')}</Typography>
            <Typography variant="body1" gutterBottom>
                <strong>{t('plan_cost')}</strong>{t('plan_cost_desc')}
            </Typography>
            <Typography variant="body1" >{t('plan_note')}</Typography>
            <Typography variant="body1" gutterBottom>{t('plan_recommendation')}</Typography>
            <Typography variant="body1">{t('plan_bye')}</Typography>
        </Box>
    )
}

export default BoatTrip;