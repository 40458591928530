import React, {useContext} from 'react'
import { useTranslation } from 'react-i18next'
import {Button, Container} from "@mui/material"
import {createStyles,makeStyles} from "@mui/styles"
import {LanguageContext} from "../../App";

const useStyles = makeStyles((theme) =>
    createStyles({
        languageSelector: {
            maxWidth: '100%',
            padding: theme.spacing(1),
            position: 'absolute',
            top: '10px', // Adjust as needed
            display: 'flex !important',
            flexWrap: 'wrap !important',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: theme.spacing(1),
            '@media (max-width:600px)': {
                position: 'static', // Resets the position for mobile
                display: 'flex',
                flexDirection: 'row', // Sets a horizontal layout for mobile
                justifyContent: 'center',
                gap: theme.spacing(1),
            },
        },
        button: {
            padding: theme.spacing(0.5, 2) + '!important', // Reduces button size
            margin: theme.spacing(0) + '!important', // Removes external margins
            fontSize: '0.8rem !important', // Smaller font size
            lineHeight: 1 + '!important', // Adjust line height to match design
            letterSpacing: 'normal', // Adjust letter spacing if needed
            backgroundColor: '#ffffff !important', // White background
            color: '#4a7c59 !important', // Green text
            '&:hover': {
                backgroundColor: '#9d9f74 !important', // Light grey for hover
            },
            '@media (max-width:600px)': {
                padding: theme.spacing(0.5, 1) + '!important', // Smaller padding for mobile
                fontSize: '0.8rem !important', // Adjust font size for mobile
            },
        },
    }))
const LanguageSelector = () => {
    const { i18n } = useTranslation();
    const classes = useStyles();
    const { selectedLanguage, setSelectedLanguage } = useContext(LanguageContext); // Access the selected language from context


    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        setSelectedLanguage(lng);
    };

    return (
        <Container className={classes.languageSelector}>
            <Button
                className={classes.button}
                onClick={() => changeLanguage('en')}
                disabled={selectedLanguage === 'en'}
            >
                English
            </Button>
            <Button
                className={classes.button}
                onClick={() => changeLanguage('es')}
                disabled={selectedLanguage === 'es'}
            >
                Español
            </Button>
            <Button
                className={classes.button}
                onClick={() => changeLanguage('de')}
                disabled={selectedLanguage === 'de'}
            >
                Deutsch
            </Button>
        </Container>
    );
};

export default LanguageSelector;
