import React, {useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
import {Container} from '@mui/material'
import {createStyles, makeStyles} from "@mui/styles"
import LanguageSelector from "./Language/LanguageSelector"
import Agenda3 from "./Agenda/Agenda3";
import Location from "./Location/Location"
import rsvpButton from "../styles/img/rsvpButton.png"
import DressCode from "./DressCode/DressCode";
import Stay from "./Stay/Stay"
import BoatTrip from "./BoatTrip/BoatTrip";
import GoodToKnow from "./GoodToKnow/GoodToKnow";
import Gift from "./Gift/Gift";
import Slideshow from "./Slideshow/Slideshow";

const useStyles = makeStyles((theme) =>
    createStyles({
        body: {
            backgroundColor: theme.palette.background.normal,
            color: theme.palette.text.primary,
            fontFamily: theme.typography.fontFamily,
        },
        section: {
            backgroundColor: theme.palette.background.normal,
            marginBottom: theme.spacing(2),
            padding: theme.spacing(2),
            zIndex: 1,
        },
        imageContainer: {
            backgroundColor: theme.palette.background.normal,
            display: 'flex !important',
            justifyContent: 'center !important',
            flexWrap: 'wrap !important',
        },
        image: {
            width: '100%',
            height: 'auto',
            objectFit: 'cover',
            display: 'block !important',
            alignContent: 'center !important',
            '@media (max-width: 600px)': {
                width: '100%',
                height: 'auto',
            }
        },
        link: {
            display: 'inline-flex', // or 'inline-block' depending on your layout
            justifyContent: 'center',
        },
        buttonImage: {
            width: '70%',
            height: 'auto',
            display: 'block !important',
            alignContent: 'center !important',
            '@media (max-width: 600px)': {
                width: '100%',
                height: 'auto',
            }
        },
        title: {
            position: 'relative',
            textAlign: 'center !important',
        },
        sideBackground: {
            backgroundColor: '#d4ddd4',
        },
    })
)

function Home() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const classes = useStyles();
    let navigate = useNavigate();

    const navigateToRSVP = () => {
        navigate('/rsvp'); // Navigate to the RSVP page
    };

    return (
        <div className={classes.sideBackground} >
            <Container maxWidth="md" disableGutters>
                <Container className={classes.imageContainer} disableGutters>
                    <img className={classes.image} src={`${process.env.PUBLIC_URL}/Welcome3.png`} alt={"welcome"}/>
                </Container>
                <Container maxWidth="md" className={classes.body}>
                    {/*<meta name="viewport" content="width=device-width, initial-scale=1.0">*/}
                    <LanguageSelector/>

                    {/* Agenda Section */}
                    <Container className={classes.section}>
                        <Agenda3/>
                    </Container>

                    {/* Place Section */}
                    <Container className={classes.section}>
                        <Location/>
                    </Container>

                    {/*RSVP*/}
                    <Container className={`${classes.imageContainer} ${classes.section}`}>
                        <a href="/rsvp" className={classes.link} onClick={(e) => { e.preventDefault(); navigateToRSVP(); }}>
                            <img src={rsvpButton} alt="RSVP Button" className={classes.buttonImage}/>
                        </a>
                    </Container>

                    <Container className={classes.section}>
                        <DressCode/>
                    </Container>

                    {/* Hotels Section */}
                    <Container className={classes.section}>
                        <Stay/>
                    </Container>

                    <Container className={classes.section} id={"boat-trip-info"}>
                        <BoatTrip />
                    </Container>

                    <Container className={classes.section}>
                        <GoodToKnow />
                    </Container>

                    <Container className={classes.section}>
                        <Gift />
                    </Container>

                    {/*slideshow*/}
                    <Container className={classes.section}>
                        <Slideshow/>
                    </Container>

                </Container>
            </Container>
        </div>
    );
}

export default Home;
