import React from 'react'
import { useTranslation } from 'react-i18next'
import {createStyles, makeStyles, useTheme} from "@mui/styles"
import {Box, Grid, Typography} from "@mui/material"
import useMediaQuery from '@mui/material/useMediaQuery';
import vanDerValk from "./img/vandervalk.png"
import plazaInn from "./img/plazaInn.png"
import bbHotel from "./img/b&bHotel.png"

const useStyles = makeStyles((theme) =>
    createStyles({
        container: {
            position: 'relative',
        },
        title: {
            position: 'relative',
        },
        image: {
            width: '100%',
            height: 'auto',
            display: 'flex !important',
            alignContent: 'center !important',
            '@media (max-width: 600px)': {
                width: '100%',
                height: 'auto',
            }
        },
        textBlock: {
            position: 'relative',
            marginBottom: theme.spacing(2),
        },
        roomType: {
            position: 'relative',
            '@media (max-width: 600px)': {
                display: 'flex',
                flexDirection: 'column',
            },
        },
        separation: {
            marginBottom: theme.spacing(2) + ' !important',
        },
        otherHotelSection: {
            flexGrow: 1,
            marginTop: theme.spacing(4),
        },
        hotelContainer: {
            alignItems: 'center',
        },
        hotelImage: {
            width: '100%', // Full width of the container
            height: 'auto', // Keep the aspect ratio of the image
        },
        hotelInfo: {
            padding: '0 !important', // Remove padding from the grid item
            '@media (max-width: 900px)': {
                textAlign: 'center',
            },
        },
        note: {
            textAlign: 'left',
            fontStyle: 'italic',
            marginTop: theme.spacing(3),
            '@media (max-width: 600px)': {
                textAlign: 'center',
            },
        },
    }))
const Stay = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    let hotels = [
        {
            name: 'Plaza Inn Ludwigsfelde',
            address: 'Kastanienweg 4, 14974 Ludwigsfelde',
            imageUrl: plazaInn,
        },
        {
            name: 'B&B Hotel Genshagen',
            address: 'Lindenweg 3, 14974 Ludwigsfelde',
            imageUrl: bbHotel,
        },
    ];
    return (
        <Box className={classes.container}>
            <Typography variant="h1" className={classes.title}>{t('stay_title')}</Typography>
            {/*Main Hotel - Van der Valk*/}
            <Box className={classes.container}>
                <img className={classes.image} src={vanDerValk} alt='Van der Valk'/>
                <Box className={classes.textBlock}>
                    <Typography variant="h2">Van der Valk</Typography>
                    <Typography variant="h4" className={classes.separation}>Berlin Brandenburg</Typography>
                    <Typography variant="body1"><strong>{t('stay_we')}</strong></Typography>
                    <Typography variant="body1" className={classes.separation}>{t('stay_with_us')}</Typography>
                    <Typography variant="body1" className={classes.roomType} component="span">
                        <Typography variant="body1" component="span"><strong>{t('room1')}</strong></Typography> {isMobile ? '' : ' - '}
                        <Typography variant="body1" component="span">{t('room1_price')}</Typography>
                    </Typography>
                    <Typography variant="body2" className={classes.separation}>{t('breakfast')}</Typography>
                    <Typography variant="body1" className={classes.roomType} component="span">
                        <Typography variant="body1" component="span"><strong>{t('room2')}</strong></Typography> {isMobile ? '' : ' - '}
                        <Typography variant="body1" component="span">{t('room2_price')}</Typography>
                    </Typography>
                    <Typography variant="body2" className={classes.separation}>{t('breakfast')}</Typography>
                    <Typography variant="body1" className={classes.roomType} component="span">
                        <Typography variant="body1" component="span"><strong>{t('room3')} </strong></Typography>
                        <Typography variant="body1" component="span"><strong>{t('room3_22')}</strong></Typography> {isMobile ? '' : ' - '}
                        <Typography variant="body1" component="span">{t('room3_price')}</Typography>
                    </Typography>
                    <Typography variant="body2" className={classes.separation}>{t('breakfast')}</Typography>
                    <Typography variant="body1" className={classes.separation} component="span">
                        <Typography variant="body1" className={classes.separation}>{t('stay_additional_info1')}</Typography>
                        <Typography variant="body1" >{t('stay_additional_info2')}</Typography>
                        <Typography variant="body1" >{t('stay_additional_info3')}</Typography>
                        <Typography variant="body1" >{t('stay_additional_info4')}</Typography>
                        <Typography variant="body1" className={classes.separation}>{t('stay_additional_info5')}</Typography>
                    </Typography>
                </Box>
                {/*Other Hotels*/}
                <Box className={classes.otherHotelSection}>
                    <Typography variant="h2">{t('otherHotels')}</Typography>
                    <Typography variant="h4">{t('inBrandenburg')}</Typography>
                    {hotels.map((hotel, index) => (
                        <Grid item key={index} xs={12} md={6} style={{ display: 'flex', justifyContent: 'center' }}>
                            <Grid container key={index} spacing={2} className={classes.hotelContainer}>
                                <Grid item md={8}>
                                    <img src={hotel.imageUrl} alt={hotel.name} className={classes.hotelImage} />
                                </Grid>
                                <Grid item xs={12} md={4} className={classes.hotelInfo}>
                                    <Typography variant="body1"><strong>{hotel.name}</strong></Typography>
                                    <Typography variant="body1">{hotel.address}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    ))}
                </Box>
                <Typography variant="body2" className={classes.note}>{t('hotel_note')}</Typography>
            </Box>
        </Box>
    );
};

export default Stay;
