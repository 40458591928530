import React from 'react';
import Carousel from 'react-material-ui-carousel';
import {Box} from '@mui/material';
import {createStyles, makeStyles} from "@mui/styles";
import image1 from './img/image1.JPG';
import image2 from './img/image2.JPG';
import image3 from './img/image3.JPG';
import image4 from './img/image4.JPG';
import image5 from './img/image5.JPG';
import image6 from './img/image6.JPG';
import image7 from './img/image7.JPG';
import image8 from './img/image8.JPG';
import image9 from './img/image9.JPG';
import image10 from './img/image10.JPG';
import image11 from './img/image11.JPG';
import image12 from './img/image12.JPG';
import image13 from './img/image13.JPG';
import image14 from './img/image14.JPG';
import image15 from './img/image15.JPG';
import image16 from './img/image16.JPG';
import image17 from './img/image17.JPG';
import image18 from './img/image18.JPG';
import image19 from './img/image19.JPG';
import image20 from './img/image20.jpg';

const useStyles = makeStyles((theme) =>
    createStyles({
     image: {
         maxWidth: '80%',
         height: 'auto',
         padding: '1rem', /* Adjust the size of the frame */
         backgroundColor: 'white',
         display: 'inline-block',
}
    })
);
function Slideshow() {
    const items = [
        {
            name: "Image 20",
            img: `${image20}`
        },
        {
            name: "Image 1",
            img: `${image1}`
        },
        {
            name: "Image 2",
            img: `${image2}`
        },
        {
            name: "Image 3",
            img: `${image3}`
        },
        {
            name: "Image 4",
            img: `${image4}`
        },
        {
            name: "Image 5",
            img: `${image5}`
        },
        {
            name: "Image 6",
            img: `${image6}`
        },
        {
            name: "Image 7",
            img: `${image7}`
        },
        {
            name: "Image 8",
            img: `${image8}`
        },
        {
            name: "Image 9",
            img: `${image9}`
        },
        {
            name: "Image 10",
            img: `${image10}`
        },
        {
            name: "Image 11",
            img: `${image11}`
        },
        {
            name: "Image 12",
            img: `${image12}`
        },
        {
            name: "Image 13",
            img: `${image13}`
        },
        {
            name: "Image 14",
            img: `${image14}`
        },
        {
            name: "Image 15",
            img: `${image15}`
        },
        {
            name: "Image 16",
            img: `${image16}`
        },
        {
            name: "Image 17",
            img: `${image17}`
        },
        {
            name: "Image 18",
            img: `${image18}`
        },
        {
            name: "Image 19",
            img: `${image19}`
        },
    ];

    return (
        <Carousel indicators={false} interval={2500}>
            {
                items.map((item, i) =>
                    <Item key={i} item={item} />
                )
            }
        </Carousel>
    )
}

function Item(props) {
    const classes = useStyles();
    return (
        <Box style={{ textAlign: 'center' }}>
            <img src={props.item.img} alt={props.item.name} className={classes.image} />
        </Box>
    )
}

export default Slideshow;