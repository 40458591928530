import React from 'react';
import { Box, Typography } from '@mui/material';
import {makeStyles, useTheme} from '@mui/styles';
import safari from './img/SafariDream2.png';
import {useTranslation} from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
        marginTop: theme.spacing(2),
    },
    giftSection: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        gap: theme.spacing(4),
        '@media (max-width: 600px)': {
            flexDirection: 'column',
            gap: theme.spacing(2),
        },
    },
    giftText: {
        maxWidth: '50%',
        textAlign: 'right',
        '@media (max-width: 600px)': {
            textAlign: 'center',
            maxWidth: '100%',
        }
    },
    giftImage: {
        maxWidth: '30%',
        height: 'auto',
        borderRadius: theme.shape.borderRadius,
        '@media (max-width: 600px)': {
            marginTop: theme.spacing(2),
            maxWidth: '80%',
        },
    },
}));

const Gift = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box className={classes.container}>
            <Typography variant="h1" gutterBottom>{t('dream_title')}</Typography>
                <Box className={classes.giftSection}>
                    {isMobile && (
                        <img
                            src={safari}
                            alt="Safari Adventure"
                            className={classes.giftImage}
                        />
                    )}
                    <Typography variant="body1" className={classes.giftText}>{t('dream_desc')}</Typography>
                    {!isMobile && <img src={safari} alt="Safari Adventure" className={classes.giftImage} />}
                </Box>
        </Box>
    );
};

export default Gift;
