import React,{createContext, useState} from "react";
import { Route, Routes } from 'react-router-dom'
import Home from './components/Home'
import RSVPForm from './components/RSVP/Form'
import ProtectedContent from "./components/Authorize/Authorize";

export const LanguageContext = createContext();


function App() {
    const [selectedLanguage, setSelectedLanguage] = useState('en'); // Default language
    const [isAuthenticated, setAuthenticated] = useState(false);

    return (
        <ProtectedContent isAuthenticated={isAuthenticated} setAuthenticated={setAuthenticated}>
            <LanguageContext.Provider value={{ selectedLanguage, setSelectedLanguage }}>
                <Routes>
                    <Route path="/" element={<Home isAuthenticated={isAuthenticated}/>} />
                    <Route path="/rsvp" element={<RSVPForm isAuthenticated={isAuthenticated}/>} />
                </Routes>
            </LanguageContext.Provider>
        </ProtectedContent>
    );
}

export default App;