import React from 'react';
import { Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    timelineContainer: {
        position: 'relative',
        textAlign: 'left',
    },
    line: {
        position: 'absolute',
        top: 0,
        bottom: 50,
        transform: 'translateX(-50%)',
        borderLeft: '4px solid #6a4f4b',
    },
    circle: {
        position: 'absolute',
        // left: '50%',
        transform: 'translateX(-50%)',
        width: theme.spacing(2),
        height: theme.spacing(2),
        backgroundColor: '#6a4f4b',
        borderRadius: '50%',
        border: '3px solid #f7e9d7',
    },
    event: {
        position: 'relative',
        margin: theme.spacing(4, 0),
    },
    eventContent: {
        marginLeft: 'calc(10%)', // Offset from the center line plus additional spacing
    },
    rsvpNote: {
        textAlign: 'left',
        fontStyle: 'italic',
        marginTop: theme.spacing(3),
        '@media (max-width: 600px)': {
            textAlign: 'center',
        },
    },
    smallText: {
        maxWidth:'90%',
        '@media (max-width: 600px)': {
            maxWidth:'100%',
        },
    },
}));

const TimelineEvent = ({ title, time, children }) => {
    const classes = useStyles();
    return (
        <Box className={classes.event}>
            <Box className={classes.circle}></Box>
            <Box className={classes.eventContent}>
                <Typography variant="h2" component="div">{title}</Typography>
                <Typography variant="body1" component="div"><strong>{time}</strong></Typography>
                <Typography variant="body1">{children}</Typography>
            </Box>
        </Box>
    );
};

const WeddingTimeline = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    return (
        <Box>
            <Typography variant="h1" gutterBottom>{t('wedding_day')}</Typography>
            <Box className={classes.timelineContainer}>
                <div className={classes.line} />
                {/* Each TimelineEvent component represents an event on the timeline */}
                <TimelineEvent title={t('wedding_date_name')} time={t('wedding_date')}/>
                <TimelineEvent title={t('guest_arrival')} time={t('guest_arrival_time')} />
                <TimelineEvent title={t('ceremony')} time={t('ceremony_time')}>
                    <Typography variant="body1" gutterBottom>{t('time_note')}</Typography>
                    <Typography variant="body1" className={classes.smallText}>{t('no_phones1')}<strong>{t('no_phones2')}</strong>{t('no_phones3')}</Typography>
                </TimelineEvent>
            </Box>
            <Typography variant="body2" className={classes.rsvpNote}>{t('rsvp_before')}</Typography>
        </Box>
    );
};

export default WeddingTimeline;
